import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import {
    Button, Typography, Grid, Link
} from '@material-ui/core';
import { TightPaper } from '../ui/CustomPaper';
import { observer } from 'mobx-react';
import { useStores } from '../hooks';
import { getViewUrl } from '../util';
import { BaseLink } from 'react-mobx-router5';
import { QrCodeComponent } from './QrCodeComponent';

interface ShareSuccessProps {
    password?: string,
    lookupKeyword?: string,
    shareId: string
}

export const ShareSuccessComponent = observer((props: ShareSuccessProps) => {
    const { routerStore } = useStores();
    // password

    const [showViewPassword, setShowViewPassword] = useState(false);
    
    const [shareUrl, setShareUrl] = useState("");

    const handleClickShowViewPassword = () => {
        setShowViewPassword(true);
    }

    const handleClickHideViewPassword = () => {
        setShowViewPassword(false);
    }

    const lookupKeyword = props.lookupKeyword;
    const password = props.password;
    const hasPassword = password && password.length;
    const hasLookupKeyward = lookupKeyword && lookupKeyword.length;
    const shareId = props.shareId;

    useEffect(() => {
        const url = getViewUrl(routerStore, shareId)
        setShareUrl(url);   
    }, [routerStore, shareId]);

    if (shareId) {
        const viewParams = {
            id: shareId
        }
        const ViewLink = React.forwardRef((props: any, ref: any) => <BaseLink routeName="view" routerStore={routerStore} routeParams={viewParams} {...props} />);
        return (
            <TightPaper>
                <Helmet>
                    <title>Share - Rapid Share</title>
                </Helmet>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography>
                            Your data has been shared at
                        </Typography>
                        <Link component={ViewLink}>
                            {shareUrl}
                        </Link>
                    </Grid>
                    { hasPassword &&
                    <Grid item xs={12}>
                        <Typography>
                            The data has been encrypted with a password
                        </Typography>
                        { showViewPassword ? 
                            <Button onClick={handleClickHideViewPassword} variant="outlined">
                                Hide Password
                            </Button>
                        :
                            <Button onClick={handleClickShowViewPassword} variant="outlined">
                                Show Password
                            </Button>

                        }
                        { showViewPassword &&
                            <Typography>
                                The password is <em>{password}</em>
                            </Typography>
                        }
                    </Grid>
                    }
                    <Grid item xs={12}>
                        {   hasLookupKeyward &&
                            <Typography>
                                The lookup keyword is <em>{lookupKeyword}</em>
                            </Typography>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <QrCodeComponent password={password} shareId={shareId} />
                    </Grid>
                </Grid>
            </TightPaper>
        );
    }
    return <div></div>;
});
