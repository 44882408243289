import React, { useState, ChangeEvent } from 'react';
import { FormControlLabel, RadioGroup, Radio } from '@material-ui/core';

interface RadioOption {
    value: string;
    label: string;
}

interface RadioGroupCustomProps {
    title: string;
    options: RadioOption[];
    customLabel: string;
    onValueSet: (value: string) => void;
    onCustomChanged?: (custom: boolean) => void;
    customField: JSX.Element;
}

export const RadioGroupCustom = ({title, options, customLabel, onValueSet, onCustomChanged, customField}: RadioGroupCustomProps) => {
    const [radioValue, setRadioValue] = useState(options[0].value);
    const [customValue, setCustomValue] = useState("");
    const [customVisible, setCustomVisible] = useState(false);

    const handleCustomChange = (event: ChangeEvent<HTMLInputElement>) => {
        setCustomValue(event.target.value);
        onValueSet(event.target.value);
    };

    const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
        setRadioValue(event.target.value);
        if (event.target.value === "custom") {
            setCustomVisible(true);
            // Will be the old value last selected
            setCustomValue(getRealValue(radioValue));
            if (onCustomChanged) {
                onCustomChanged(true);
            }
        } else {
            setCustomVisible(false);
            if (onCustomChanged) {
                onCustomChanged(false);
            }
            onValueSet(getRealValue(event.target.value));
        }
    };

    const getRealValue = (value: string): string => {
        if (value === "empty") {
            return ""
        }
        return value;
    }
    return (
        <RadioGroup aria-label={title} name={title} value={`${radioValue}`} onChange={handleRadioChange}>
            {options.map((option) => (
                <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
            ))}
            <FormControlLabel value="custom" control={<Radio />} label={customLabel} />
            {customVisible &&
                React.cloneElement(customField, {
                    value: customValue,
                    onChange: handleCustomChange
                })
            }
        </RadioGroup>
    )
};

