import React, { ChangeEvent, useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

import { uiLogger } from '../loggers';
import { Grid, Link, Typography } from '@material-ui/core';
import { TightPaper } from '../ui/CustomPaper';
import { observer } from 'mobx-react';
import { transportLayer } from '../TransportLayer';
import { useAutorun, useStores } from '../hooks';
import { getViewUrl } from '../util';
import { Helmet } from 'react-helmet';
import Linkify from 'react-linkify';
import { QrCodeComponent } from './QrCodeComponent';

export const ViewPage = observer(() => {
    const { routerStore } = useStores();

    const [data, setData] = useState("");
    const [password, setPassword] = useState("");
    const [unencryptedData, setUnencryptedData] = useState("");
    const [foundShare, setFoundShare] = useState(true);
    const [lookupKeyword, setLookupKeyword] = useState("");
    const [shareId, setShareId] = useState("")
    const [encrypted, setEncrypted] = useState(false);

    const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    useEffect(() => {
        const decrypted = AES.decrypt(data, password);
        try {
            setUnencryptedData(`${decrypted.toString(Utf8)}`)
        } catch {
            setUnencryptedData("Unable to decrypt");
            uiLogger.error("Unable to decrypted data");
        }
    }, [data, password])

    useAutorun(async () => {
        uiLogger.debug("Running autorun for view page get")();
        const shareId = routerStore.route.params['id'];
        const lookupResult = await transportLayer.get(shareId)
        if (lookupResult) {
            setData(lookupResult.data);
            setLookupKeyword(lookupResult.lookupKeyword ? lookupResult.lookupKeyword : "");
            setEncrypted(lookupResult.encrypted);
        } else {
            setData(`Unable to find the share with id ${shareId}`)
            setFoundShare(false);
        }
    });

    useAutorun(() => {
        setShareId(routerStore.route.params['id']);
        const urlPassword = routerStore.route.params['password'];
        if (urlPassword) {
            setPassword(atob(urlPassword));
            // Re-route to view w/out the password so the URL can't be copied
            routerStore.navigate("view", {
                id: routerStore.route.params['id']
            });
        }
    });
    if (shareId) {
        const url = getViewUrl(routerStore, shareId)
        return (
            <TightPaper>
                <Helmet>
                    <title>View Share - Rapid Share</title>
                </Helmet>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <fieldset aria-hidden style={{
                            overflowWrap: "anywhere"
                        }}>
                            <legend>
                                Shared data
                            </legend>
                            <Linkify
                                componentDecorator={(decoratedHref, decoratedText, key) => (
                                    <a target="blank" href={decoratedHref} key={key}>
                                        {decoratedText}
                                    </a>
                                )}
                            >
                                {data}
                            </Linkify>
                        </fieldset>
                        {/* <TextField multiline disabled variant="outlined" label="Shared data" rows="4" fullWidth
                            size="small"
                            value={data}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        /> */}
                    </Grid>
                    { foundShare && 
                        <React.Fragment>
                            { encrypted && 
                            <Grid item xs={12} sm={6}>
                                <TextField variant="outlined" label="Optional Password" placeholder="Enter a password" fullWidth
                                    size="small"
                                    helperText="Input the password to decrypt the data"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            }
                            <Grid item xs={12} sm={encrypted ? 6 : 12}>
                                <TextField disabled variant="outlined" label="Optional Lookup Keyword" fullWidth
                                    size="small"
                                    value={lookupKeyword}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            { encrypted && 
                            <Grid item xs={12}>
                                <fieldset aria-hidden style={{
                                    overflowWrap: "anywhere"
                                }}>
                                    <legend>
                                        Unencrypted shared data
                                    </legend>
                                    <Linkify
                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                            <a target="blank" href={decoratedHref} key={key}>
                                                {decoratedText}
                                            </a>
                                        )}
                                    >
                                        {unencryptedData}
                                    </Linkify>
                                </fieldset>
                                {/* <TextField multiline disabled variant="outlined" label="Unencryped shared data" rows="4" fullWidth
                                    size="small"
                                    value={unencryptedData}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /> */}
                            </Grid>
                            }
                            <Grid item xs={12}>
                                <Typography>
                                    This share is available at
                                </Typography>
                                <Link href={url}>
                                    { url }
                                </Link>
                                <QrCodeComponent password={password} shareId={shareId} />
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>
            </TightPaper>
        );
    }
    // TODO: Update loading
    return <div>loading</div>
});
