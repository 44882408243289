import { RouterStore } from "mobx-router5";

export const getViewUrl = (routerStore: RouterStore, shareId: string) => {
    return window.location.protocol + "//" + window.location.host + routerStore.router.buildUrl("view", {
        id: shareId
    });
};

export const getViewUrlWithPassword = (routerStore: RouterStore, shareId: string, password: string) => {
    return window.location.protocol + "//" + window.location.host + routerStore.router.buildUrl("view", {
        id: shareId,
        password: btoa(password)
    });
};

