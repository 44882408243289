import React, { ChangeEvent, MouseEvent, useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import AES from 'crypto-js/aes';
import { Helmet } from 'react-helmet';

import { uiLogger } from '../loggers';
import {
    Button, Typography, FormControl, FormLabel, FormHelperText, Grid, InputAdornment, IconButton
} from '@material-ui/core';
import { TightPaper } from '../ui/CustomPaper';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { transportLayer } from '../TransportLayer';
import { useAutorun, useStores } from '../hooks';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { getWords } from '../util/PasswordGenerator';
import { RadioGroupCustom } from '../ui/RadioGroupCustom';
import { ShareSuccessComponent } from './ShareSuccessComponent';

const MIN_TIME = 1;
const MAX_TIME = 60;

export const SharePage = observer(() => {
    const { routerStore } = useStores();
    // TODO: These could just be local react state
    const showPassword = React.useRef(observable.box(false)).current;
    const expirationTime = React.useRef(observable.box(5)).current;
    const customExpirationTime = React.useRef(observable.box("5")).current;
    const unencryptedData = React.useRef(observable.box("")).current;
    const encryptedData = React.useRef(observable.box("")).current;
    const [encrypted, setEncrypted] = React.useState(false);
    // password
    const password = React.useRef(observable.box("")).current;
    const [customPasswordVisible, setCustomPasswordVisible] = useState(false);
    const [generatedPassword, setGeneratedPassword] = useState("");
    
    const [lookupKeyword, setLookupKeyword] = useState("");
    const [generatedLookupKeyword, setGeneratedLookupKeyword] = useState("");

    const handleDataChange = (event: ChangeEvent<HTMLInputElement>) => {
        unencryptedData.set(event.target.value);
    };

    // TODO: Perhaps use useCallback per performance optimization?
    const handlePasswordChange = (value: string) => {
        password.set(value);
    };

    const handleLookupKeywordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setLookupKeyword(event.target.value);
    };

    const handleExpirationTime = (value: string) => {
        let number = Number(value);
        if (!Number.isNaN(number) && Number.isFinite(number)) {
            number = Math.min(Math.max(MIN_TIME, number), MAX_TIME);
            expirationTime.set(number);
            customExpirationTime.set(`${number}`)
        }
    }

    const handleCustomExpirationChange = (event: ChangeEvent<HTMLInputElement>) => {
        handleExpirationTime(event.target.value);
    };

    const handleClickShowPassword = () => {
        showPassword.set(!showPassword.get());
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const shareData = async (event: MouseEvent) => {
        uiLogger.debug("Share clicked", event)();
        const shareObject = {
            data: encryptedData.get(),
            expirationTimeMinutes: Number(expirationTime.get()),
            lookupKeyword: lookupKeyword,
            encrypted: encrypted
        };
        const shareId = await transportLayer.share(shareObject);

        routerStore.navigate("share.success", {
            id: shareId
        });
    };

    useAutorun(() => {
        if (unencryptedData.get()) {
            if (password.get()) {
                encryptedData.set(`${AES.encrypt(unencryptedData.get(), password.get())}`);
                setEncrypted(true);
            } else {
                encryptedData.set(unencryptedData.get());
                setEncrypted(false);
            }
        }
    }, 250)

    useEffect(() => {
        const words = getWords(6);
        const password = words.map((word) => word.word).join(" ");
        setGeneratedPassword(password);

        const lookupwords = getWords(2);
        const lookupKeyword = lookupwords.map((word) => word.word).join(" ");
        setGeneratedLookupKeyword(lookupKeyword);
    }, []);

    if (routerStore.route.name === "share.success") {
        return <ShareSuccessComponent password={password.get()} lookupKeyword={lookupKeyword} shareId={routerStore.route.params['id']} />;
    }
    return (
        <TightPaper>
            <Helmet>
                <title>Share - Rapid Share</title>
            </Helmet>
            <form>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography>
                            Enter in the text data you want to share.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField multiline variant="outlined" label="Data to Share" placeholder="Enter data to share" rows="6" fullWidth
                            size="small"
                            autoFocus
                            value={unencryptedData.get()}
                            onChange={handleDataChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Password</FormLabel>
                            <RadioGroupCustom
                                title="password"
                                options={[{
                                    value: "empty", 
                                    label: "No Password"
                                }, {
                                    value: generatedPassword,
                                    label: generatedPassword
                                }]}
                                customLabel="Custom password"
                                onValueSet={handlePasswordChange}
                                onCustomChanged={setCustomPasswordVisible}
                                customField={
                                    <TextField variant="outlined" label="Optional Password" placeholder="Enter a password" fullWidth
                                        size="small"
                                        type={showPassword.get() ? "text" : "password"}
                                        autoComplete="new-password"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        autoFocus
                                        InputProps={{
                                            endAdornment: (<InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword.get() ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>)
                                        }}
                                    />
                                }
                            />
                            <FormHelperText>
                                { password.get().length === 0 ? "Your data won't be encrypted" :
                                   customPasswordVisible ? "Your data will be encrypted with your custom password" : 
                                        "Your data will be encrypted with the generated password"
                                }
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Lookup Keyword</FormLabel>
                            <RadioGroupCustom
                                title="lookup keyword"
                                options={[{
                                    value: "empty", 
                                    label: "No Keyword"
                                }, {
                                    value: generatedLookupKeyword,
                                    label: generatedLookupKeyword
                                }]}
                                customLabel="Custom lookup keyword"
                                onValueSet={setLookupKeyword}
                                customField={
                                    <TextField variant="outlined" label="Optional Lookup Keyword" fullWidth
                                        size="small"
                                        placeholder="Enter a keyword"
                                        autoFocus
                                        value={lookupKeyword}
                                        onChange={handleLookupKeywordChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                }
                            />
                            <FormHelperText>
                                { lookupKeyword.length === 0 ? "Your data won't have a lookup keyword" :
                                   `Your lookup keyword will be '${lookupKeyword}'`
                                }
                            </FormHelperText>
                        </FormControl> 
                    </Grid>
                    <Grid item xs={12}>
                        <TextField disabled variant="outlined" color="secondary" label="Data to be stored on server" fullWidth
                            size="small"
                            value={encryptedData.get()}
                            helperText="This is the data that will be stored on the server"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Expiration</FormLabel>
                            <RadioGroupCustom
                                title="expiration"
                                options={[{
                                    value: "5", 
                                    label: "Expire after 5 minutes"
                                }, {
                                    value: "10",
                                    label: "Expire after 10 minutes"
                                }]}
                                customLabel="Custom expiration time"
                                onValueSet={handleExpirationTime}
                                customField={
                                    <TextField variant="outlined" label="Custom expiration time in minutes" type="number"
                                        helperText="Value must be between 1 and 60 minutes"
                                        size="small"
                                        value={customExpirationTime.get()}
                                        onChange={handleCustomExpirationChange}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">minutes</InputAdornment>
                                        }}
                                    />
                                }
                            />
                            <FormHelperText>After {expirationTime.get()} {expirationTime.get() === 1 ? "minute" : "minutes"} the data will be expired and unaccessible.</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" onClick={shareData}>Share Data</Button>
                    </Grid>
                </Grid>
            </form>
        </TightPaper>
    );
});
