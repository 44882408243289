import axios, { AxiosInstance } from 'axios';
import { transportLogger } from './loggers';

export interface SharePostBody {
    data: string;
    expirationTimeMinutes: number;
    lookupKeyword: string;
    encrypted: boolean
}

export interface LookupResult {
    id: string;
    data: string;
    lookupKeyword: string;
    encrypted: boolean;
}

export interface LookupResults {
    results: LookupResult[];
}

class TransportLayer {
    instance: AxiosInstance;

    constructor() {
        this.instance = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL,
            timeout: 5000,
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY
            }
        });
    }

    async share(share: SharePostBody): Promise<string> {
        return this.instance.post(`/share/`, share).then((ret) => {
            return Promise.resolve(ret.data);
        });
    }

    async get(id: string): Promise<LookupResult> {
        transportLogger.debug(`Getting share with id ${id}`)();
        return this.instance.get(`/get/${id}`).then((ret) => {
            return Promise.resolve(ret.data);
        });
    }

    async lookup(keyword: string): Promise<LookupResults> {
        return this.instance.get(`/lookup`, {
            params: {
                keyword: keyword
            }
        }).then((ret) => {
            return Promise.resolve(ret.data);
        });
    }
}

const transportLayer = new TransportLayer();

export {
    transportLayer
};
