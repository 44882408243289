import React, { ChangeEvent, useState, useEffect } from 'react';
import QRCode from 'qrcode.react';

import {
    Typography, Checkbox, FormControlLabel
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { useStores } from '../hooks';
import { getViewUrl, getViewUrlWithPassword } from '../util';

interface QrCodeProps {
    password?: string,
    shareId: string
}

export const QrCodeComponent = observer((props: QrCodeProps) => {
    const { routerStore } = useStores();
    // password
    const [encodePasswordInQrCode, setEncodePasswordInQrCode] = useState(false);
    
    const [qrCodeShareUrl, setQrCodeShareUrl] = useState("");

    const handleEncodePasswordInQrCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEncodePasswordInQrCode(!encodePasswordInQrCode);
    };


    const password = props.password;
    const hasPassword = password && password.length;
    const shareId = props.shareId;

    useEffect(() => {
        const url = getViewUrl(routerStore, shareId)
        if (hasPassword && encodePasswordInQrCode && password != null) {
            const qrCodeUrl = getViewUrlWithPassword(routerStore, shareId, password);
            setQrCodeShareUrl(qrCodeUrl);
        } else {
            setQrCodeShareUrl(url);
        }
    }, [routerStore, shareId, hasPassword, encodePasswordInQrCode, password]);

    return (
        <React.Fragment>
            <Typography>
                Here is a QR Code of the view URL
            </Typography>
            { hasPassword && 
                <div>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={encodePasswordInQrCode}
                            onChange={handleEncodePasswordInQrCodeChange}
                            color="primary"
                        />
                        }
                        label="Encode Password within QR Code"
                    />
                </div>
            }
            <QRCode value={qrCodeShareUrl} />
            { encodePasswordInQrCode &&
            <Typography>
                <b>This QR Code contains an embedded version of your password.  Be mindful when sharing it.</b>
            </Typography>
            }
        </React.Fragment>
    );
});
