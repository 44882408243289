import React, { useEffect } from 'react';
import { routerStore } from './stores/stores';
import { autorun } from 'mobx';

const storesContext = React.createContext({
  routerStore
});

export const useStores = () => {
  const store = React.useContext(storesContext)
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error('useStore must be used within a StoreProvider.')
  }
  return store
}

// TODO: Not sure if this is the correct way to perform an update when the text or
// password are changed
export const useAutorun = (func: () => void, delay: number = 0) => {
  useEffect(() => {
    return autorun(() => {
        func();
    }, { delay: delay });
  // autorun ensures things get update so we don't need a depedency list for useEffect
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
