import {Logger, LoggerFactory, LogStrict} from 'lines-logger';

let factory: LoggerFactory = new LoggerFactory();

factory.setLogWarnings(LogStrict.DEBUG);

export const transportLogger: Logger = factory.getLoggerColor('transport', 'yellow');
export const storeLogger: Logger = factory.getLoggerColor('store', 'blue');
export const uiLogger: Logger = factory.getLoggerColor('ui', 'green');
export const reactLogger: Logger = factory.getLoggerColor('react', 'brown');
export const formLogger: Logger = factory.getLoggerColor('form', 'orange');
export const realtimeLogger: Logger = factory.getLoggerColor('form', 'red');
