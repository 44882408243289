import React, { ChangeEvent, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { Helmet } from 'react-helmet';

import { Grid, Typography, Link, List, ListItem } from '@material-ui/core';
import { TightPaper } from '../ui/CustomPaper';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { transportLayer, LookupResults } from '../TransportLayer';
import { useAutorun, useStores } from '../hooks';
import { getViewUrl } from '../util';
import { BaseLink } from 'react-mobx-router5';

export const SearchPage = observer(() => {
    const { routerStore } = useStores()

    const lookupKeyword = React.useRef(observable.box("")).current;
    const lookupResults = React.useRef(observable({
        results: []
    } as LookupResults)).current;

    const [loading, setLoading] = useState(true);

    const handleLookupKeywordChanged = (event: ChangeEvent<HTMLInputElement>) => {
        lookupKeyword.set(event.target.value);
    };

    useAutorun(async () => {
        setLoading(true);
        lookupResults.results = (await transportLayer.lookup(lookupKeyword.get())).results;
        setLoading(false);
    });

    return (
        <TightPaper>
            <Helmet>
                <title>Search Share - Rapid Share</title>
            </Helmet>
            <form>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography>
                            Enter in a lookup keyword to filter
                    </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField variant="outlined" label="Lookup Keyword" placeholder="Enter a lookup keyword to filter" fullWidth
                            value={lookupKeyword.get()}
                            onChange={handleLookupKeywordChanged}
                            autoFocus
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    { !loading && (lookupResults.results.length === 0 ?
                            <Grid item xs={12}>
                                <Typography>
                                    We didn't find any shares {lookupKeyword.get() ? `for lookup keyword '${lookupKeyword.get()}'` : ''}
                                </Typography>
                            </Grid>
                            :
                            <Grid item xs={12}>
                                <Typography>
                                    We found the following shares
                                </Typography>
                                <List>
                                    {
                                        lookupResults.results.map((result) => {
                                            const url = getViewUrl(routerStore, result.id);
                                            const viewParams = {
                                                id: result.id
                                            }
                                            const ViewLink = React.forwardRef((props: any, ref: any) => <BaseLink routeName="view" routerStore={routerStore} routeParams={viewParams} {...props} />);
                                            return (
                                                <ListItem key={result.id} >
                                                    <Link component={ViewLink}>
                                                        {result.id}
                                                    </Link>
                                        &nbsp;
                                                    { result.lookupKeyword &&
                                                        <span>
                                                            ({result.lookupKeyword})
                                                        </span>
                                                    }
                                                </ListItem>
                                            );
                                        })
                                    }
                                </List>
                            </Grid>
                    )}
                </Grid>
            </form>
        </TightPaper>
    );
});
