import React from 'react';
import { observer } from 'mobx-react'

import { useStores } from './hooks';
import { SharePage } from './pages/SharePage';
import { Container, ButtonGroup, Button, Theme, createStyles, makeStyles } from '@material-ui/core';
import { ViewPage } from './pages/ViewPage';
import { SearchPage } from './pages/SearchPage';
import { BaseLink } from 'react-mobx-router5';


const useStyles = makeStyles((theme: Theme) => createStyles({
  activeFilter: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
        backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
        // color: "black"
    }
}
}));

const App = observer(() => {
  const { routerStore } = useStores();
  const classes = useStyles();
  const renderComponent = () => {
    const routeName = routerStore.route?.name;
    switch (routeName) {
        case 'share.success':
        case 'share': 
          return <SharePage />;
        case 'view': return <ViewPage />;
        case 'search': return <SearchPage />;
    }
    return <div>App unable to figure out route {routeName}</div>;
  };
  const NewShareLink = React.forwardRef((props: any, ref: any) => <BaseLink routeName="share" routerStore={routerStore} {...props} />);
  const SearchShareLink = React.forwardRef((props: any, ref: any) => <BaseLink routeName="search" routerStore={routerStore} {...props} />);
  return (
    <Container maxWidth="md">
      <ButtonGroup
          fullWidth
          aria-label="Page navigation"
          >
          <Button component={NewShareLink} className={routerStore.route?.name === "share" ? classes.activeFilter : ""}>New Share</Button>
          <Button component={SearchShareLink}className={routerStore.route?.name === "search" ? classes.activeFilter : ""}>Find Share</Button>
      </ButtonGroup>  
      { renderComponent() }
    </Container>
  );
});

export default App;
