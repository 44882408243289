import { Paper, createStyles, Theme, withStyles } from '@material-ui/core';

const centerStyles = ({ palette, spacing, breakpoints }: Theme) => createStyles({
    root: {
        padding: spacing(1),
        [breakpoints.up(600 + spacing(3) * 2)]: {
            padding: spacing(2),
        },
        margin: 'auto',
        marginTop: '30px',
        marginBottom: '30px',
        textAlign: 'center',
        maxWidth: '400px',
    },
});

export const CenterPaper =  withStyles(centerStyles)(Paper);

const comfyStyle = ({ palette, spacing, breakpoints }: Theme) => createStyles({
    root: {
        padding: spacing(1),
        [breakpoints.up(600 + spacing(3) * 2)]: {
            padding: spacing(2),
        },
    },
});

export const ComfyPaper =  withStyles(comfyStyle)(Paper);

const tightStyle = ({ palette, spacing, breakpoints }: Theme) => createStyles({
    root: {
        padding: spacing(0.5),
        [breakpoints.up(600 + spacing(3) * 2)]: {
            padding: spacing(1),
        },
        marginTop: spacing(2),
        marginBottom: spacing(2),
    },
});

export const TightPaper =  withStyles(tightStyle)(Paper);
